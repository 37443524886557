import React from "react";
import Footer from "../../Footer";
import Navbar from "../../Navbar";
import Banner from "./Banner";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import HomeSlider from "./HomeSlider";
import Navbar2 from "../../Navbar2";
import MobileNavbar from "../../MobileNavbar";

export default function HomePage() {
  const bgBanner1 = "/images/home-b-1.jpg";
  const bgBanner2 = "/images/home-b-2.jpeg";
  return (
    <div>
      <div>
        <div className="hidden lg:block">
          <Navbar />
        </div>
        <div className="block lg:hidden">
          <MobileNavbar />
        </div>
      </div>
      <Banner />
      {/* start section 1 */}
      <div className="mt-10 md:mx-52 md:flex md:flex-row md:space-x-20 flex flex-col mx-8">
        <div className=" md:h-96 md:w-96">
          <img
            className=" md:h-96 md:w-96"
            src="/images/home-section1.jpg"
            alt=""
          />
        </div>
        {/* end left */}
        <div>
          <h1 className=" text-black font-extrabold text-3xl mt-4 mb-4">
            Energy Works Wonders
          </h1>
          <div className="w-96 mb-4">
            <p className=" text-gray-400 text-sm ">
              BD Electrical Services Engineering is a Bangladeshi company
              headquartered in Dhaka, Bangladesh with operations in the
              technology, engineering, construction, power and energy sector.
            </p>
          </div>
          <div className="w-96 mb-4">
            <p className=" text-gray-400 text-sm ">
              Worked as Chief Engineer, System Operation & SCADA under Dhaka
              Power Distribution Company Ltd (DPDC) from August 2012 to December
              2013 and entrusted with the operation of the electrical network of
              DPDC ranging from 132kv to downwards. It includes operation and
              maintenance of 132/33kV, 33/11kV S/S, 132 kV UG/OH lines and 33kV
              UG/OH lines.
            </p>
          </div>
          <button className=" bg-blue-600 text-white uppercase px-4 py-2 font-medium text-xs">
            about company
          </button>
        </div>
      </div>
      {/* end section 1 */}
      {/* start section 2 */}
      <div
        style={{
          backgroundImage: `url(${bgBanner1})`,
          backgroundSize: "cover",
        }}
        className=" h-[500px] lg:h-[600px] bg-zinc-900 md:my-10 my-6 "
      >
        <div className="md:py-44 py-10 px-16">
          <h3 className="text-white font-bold text-xl mb-5 text-center md:text-left">
            Mission & Vision
          </h3>
          <div className="w-96">
            <p className="text-gray-900 text-sm">
              Bd Electrical Services embodies who we are, individually and as a
              company, and reflects our core values and the path we follow in
              pursuit of providing our clients with unmatched performance,
              unparalleled accountability, and the highest-level of satisfaction
              in the industry.
            </p>
            <p className="text-gray-900 text-sm my-4">
              Our main mission is to achieve customer's satisfaction by
              providing the best service according to their demand in committed
              time. We always focus on continuous improvement in products &
              Service
            </p>
          </div>
        </div>
      </div>
      {/* end section 2 */}
      {/* start section slider */}
      <div className=" flex justify-center items-center my-5">
        <h1 className=" text-black font-bold text-2xl">Our Recent Works</h1>
      </div>
      <HomeSlider />

      {/* end section slider */}

      {/* start section 3 */}
      <div
        style={{
          backgroundImage: `url(${bgBanner2})`,
          backgroundSize: "cover",
        }}
        className=" h-[500px] lg:h-[600px] bg-zinc-900 md:my-10 my-6 "
      >
        <div className="md:flex md:justify-center md:items-center md:pt-52 flex justify-center items-center pt-44">
          <div>
            <h3 className="text-white font-bold text-2xl mb-5 text-center md:text-left">
              Why BD Electrical Services
            </h3>
            <div className="w-96">
              <p className="text-white text-sm">
                BD Electrical Services has delivered Engineering services and
                offer a wide range of electrical, Mechanical and automation
                products and control systems for industries. Therefore,
                Customers have wide liberty to decide from alternatives.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* end section 3 */}
      {/* start statistic */}
      <div className=" my-6 md:flex md:flex-row md:justify-between md:mx-44 flex flex-col space-y-4">
        <div className=" space-y-1 text-center">
          <p className="text-black text-2xl font-medium">64</p>
          <p className=" text-gray-400 text-lg uppercase">Districts</p>
          <div className=" flex justify-center items-center">
            <div className=" h-6 w-0.5 bg-orange-500 "></div>
          </div>
        </div>
        <div className=" space-y-1 text-center">
          <p className="text-black text-2xl font-medium">100+</p>
          <p className=" text-gray-400 text-lg uppercase">projects</p>
          <div className=" flex justify-center items-center">
            <div className=" h-6 w-0.5 bg-orange-500 "></div>
          </div>
        </div>
        <div className=" space-y-1 text-center">
          <p className="text-black text-2xl font-medium">300+</p>
          <p className=" text-gray-400 text-lg uppercase">employees</p>
          <div className=" flex justify-center items-center">
            <div className=" h-6 w-0.5 bg-orange-500 "></div>
          </div>
        </div>
        <div className=" space-y-1 text-center">
          <p className="text-black text-2xl font-medium">7+</p>
          <p className=" text-gray-400 text-lg uppercase">years</p>
          <div className=" flex justify-center items-center">
            <div className=" h-6 w-0.5 bg-orange-500 "></div>
          </div>
        </div>
      </div>
      {/* end statistic */}
      <Footer />
    </div>
  );
}
