import React from "react";
import Footer from "../Footer";
import MobileNavbar from "../MobileNavbar";
import Navbar from "../Navbar";

export default function AboutUs() {
  const bgBanner1 = "/images/aboutus.jpg";
  const bgBanner2 = "/images/bg-about.jpg";
  return (
    <div>
      <div>
        <div className="hidden lg:block">
          <Navbar />
        </div>
        <div className="block lg:hidden">
          <MobileNavbar />
        </div>
      </div>

      {/* section 1 */}

      <div
        style={{
          backgroundImage: `url(${bgBanner1})`,
          backgroundSize: "cover",
        }}
        className="h-96 w-full flex justify-center items-center "
      >
        <h1 className=" text-white font-bold md:text-4xl text-2xl uppercase">
          About bd electrical services
        </h1>
      </div>

      {/* end section 1 */}

      {/* section 2 */}

      <div className=" flex justify-center items-center my-10">
        <img src="/images/madeinbanglades.jpg" alt="bd" />
      </div>

      {/* end section 2 */}

      {/* section 3 */}

      <div
        style={{
          backgroundImage: `url(${bgBanner2})`,
          backgroundSize: "cover",
        }}
        className=" md:h-[900px] md:w-full flex justify-center items-center "
      >
        <div className=" bg-white md:m-32 md:w-3/4 ">
          <div className=" flex justify-center items-center my-3">
            <p className=" text-black font-bold text-xl">ABOUT US</p>
          </div>
          <p className=" text-gray-400 pl-8 pr-8 text-sm mb-3">
            BD Electrical Services is a service based Manufacturing Company
            registered in Bangladesh, provides superior services including
            manufacturing, installation & field investigation in Commercial,
            Industrial and Residential market in Bangladesh. Our Company has an
            extensive amount of experience in troubleshoot, maintenance,
            up-gradation & Modification of Diesel Generator, Transformer, HT
            Switch gear (VCB/LBS), LT Switch gear (MCCB/ACB), PFI Panel, ON GRID
            Solar System, ATS, UPS, IPS etc.& Instrument work special team
            support. BD Electrical Services also Import Diesel Generator &
            Solar, Brand Name Perkins, Cummins, Ricardo
          </p>
          <p className=" text-gray-400 pl-8 pr-8 text-sm mb-3">
            Is foremost one stop solution general engineering company, Like EPC
            offering contracting services, particularly in the public and
            private sectors throughout the People’s Republic of Bangladesh.
            Within short span of time the company expanded its business to
            critical Technical service, Project work as well as supply of
            equipment`s & machinery to different industries. BD Electrical
            Services successfully completed numerous large scale turn key
            projects for many prestigious clients in different engineering
            sector including: -
          </p>
          <ul className="pl-8 pr-8 space-y-2 mb-4">
            <li className=" text-gray-400  text-sm">
              - Engineering Consultancy.
            </li>
            <li className=" text-gray-400  text-sm">
              - Power generation and substation.
            </li>
            <li className=" text-gray-400  text-sm">
              - Soil Test, Pile Foundation, Bore Pile, Digital Survey, Pile
              Integrity Test, Pile Load Test, Earth Resistance Test, Non
              Destructive Test, Semi Destructive Test etc.
            </li>
            <li className=" text-gray-400  text-sm">- Paper Mills.</li>
            <li className=" text-gray-400  text-sm">- Chemical Industries.</li>
            <li className=" text-gray-400  text-sm">- Steel Plants.</li>
            <li className=" text-gray-400  text-sm">- Sugar Mills.</li>
            <li className=" text-gray-400  text-sm">- Pharmaceuticals.</li>
            <li className=" text-gray-400  text-sm">- Textiles mills.</li>
            <li className=" text-gray-400  text-sm">
              - Shipbuilding Engineering.
            </li>
            <li className=" text-gray-400  text-sm">- Cement Industry.</li>
            <li className=" text-gray-400  text-sm">
              - Other Engineering Sectors.
            </li>
          </ul>
          <p className=" text-gray-400 pl-8 pr-8 text-sm md:mb-6">
            Our commitment to execute project with highest quality, at on
            schedule time as well as within client budget while adhering
            excellent standard of health, safety and environmental positioned
            thus BD Electrical Services to be recognized as an icon in the
            engineering commodity in Bangladesh. We are proud that our company
            is backed by a group of committed professionals and qualified
            engineers with proven expertise. Their cumulative experience and
            knowledge ensure professional and technical assistance to customer
            by providing the best products / services from the world-renowned
            manufacturers.
          </p>
        </div>
      </div>

      {/* end section 3 */}
      <Footer />
    </div>
  );
}
