import React from "react";
import Footer from "../Footer";
import MobileNavbar from "../MobileNavbar";
import Navbar from "../Navbar";

export default function Environment() {
  const bgBanner1 = "/images/environment.jpg";
  return (
    <div>
      <div>
        <div className="hidden lg:block">
          <Navbar />
        </div>
        <div className="block lg:hidden">
          <MobileNavbar />
        </div>
      </div>

      {/* section 1 */}

      <div
        style={{
          backgroundImage: `url(${bgBanner1})`,
          backgroundSize: "cover",
        }}
        className="h-96 w-full flex justify-center items-center "
      >
        <h1 className=" text-white font-bold md:text-4xl text-2xl uppercase">
          ENVIRONMENTAL POLICY
        </h1>
      </div>

      {/* end section 1 */}

      {/* section 2 */}

      <div className="my-10 mx-16 flex justify-center items-center flex-col md:items-start">
        <p className=" text-gray-800 text-lg mb-4">
          BD Electrical Services will actively seek to plan, conduct and monitor
          operations, using the best practical means, so as to limit adverse
          effects on the physical environment.
        </p>
        <p className=" text-gray-800 text-lg mb-4">
          The duty of care will be achieved by the implementation of the
          following Operational Policy:
        </p>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-8  w-4 bg-gray-700"></div>
          <p className=" text-gray-800 text-lg ">
            All Environmental Policies will be reviewed, formalized and endorsed
            by the Company’s Board of Directors.
          </p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-8 w-4 bg-gray-700"></div>
          <p className=" text-gray-800 text-lg ">
            To employ systems and procedures that ensures compliance with
            relevant environmental legislation.
          </p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-8 w-4 bg-gray-700"></div>
          <p className=" text-gray-800 text-lg ">
            To purchase, where possible, goods and services which are
            environmentally friendly, and to utilize in an appropriate and
            efficient manner.
          </p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-8 w-4 bg-gray-700"></div>
          <p className=" text-gray-800 text-lg ">
            To operate and maintain all plant, machinery and vehicles in a
            responsible manner providing the maximum practicable environmental
            protection.
          </p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-14 w-4 bg-gray-700"></div>
          <p className=" text-gray-800 text-lg ">
            Endeavour to assess in advance and operate all works with due care
            for the local and global environment and to the quality of life of
            the local communities, where such works may take place.
          </p>
        </div>
      </div>

      {/* end section 2 */}
      <Footer />
    </div>
  );
}
