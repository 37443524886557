import React from "react";
import Footer from "../Footer";
import MobileNavbar from "../MobileNavbar";
import Navbar from "../Navbar";

export default function Projects() {
  const bgBanner1 = "/images/project.webp";
  return (
    <div>
      <div>
        <div className="hidden lg:block">
          <Navbar />
        </div>
        <div className="block lg:hidden">
          <MobileNavbar />
        </div>
      </div>
      {/* section 1 */}
      <div
        style={{
          backgroundImage: `url(${bgBanner1})`,
          backgroundSize: "cover",
        }}
        className="h-96 w-full flex justify-center items-center "
      >
        <h1 className=" text-white font-bold md:text-4xl text-2xl uppercase">
          our projects
        </h1>
      </div>
      {/* end section 1 */}

      {/* section 2 */}
      <div className=" flex justify-center items-center my-10 flex-col">
        <h1 className=" text-black uppercase font-bold text-2xl mb-3">
          PROJECTS ALL ACROSS THE Country
        </h1>
        <div className="md:w-[650px] w-96">
          <p>
            The above work included Supply, erection, testing, programming,
            Synchronizing and commissioning of whole machine network (PLC, HMI).
            Since its completion & starting commercial operation in April 2019.
            AC Drives: It included 05 Nos. AC Drives & PLC from Siemens. DC
            Drive: It includes 07 Nos. Digital DC drives & PLC from Siemens
          </p>
        </div>
      </div>

      {/* end section 2 */}

      {/* section 3 */}
      <div className="my-5 mx-10">
        <div className="flex justify-center items-center ">
          <h1 className=" text-black text-2xl uppercase my-4 font-bold">
            LIST OF MAJOR ONGOING PROJECTS
          </h1>
        </div>
        <div className="overflow-x-auto">
          <table className="table w-full ">
            {/* <!-- head --> */}
            <thead>
              <tr>
                <th></th>
                <th>Description</th>
                <th>Client’s name</th>
                <th>Location</th>
              </tr>
            </thead>
            <tbody>
              {/* <!-- row 1 --> */}
              <tr>
                <th>1</th>
                <td>
                  Installation, Testing & Commissioning of compact sub-station
                  equipment
                </td>
                <td>HILTON HOTEL Project</td>
                <td>Gulshan Avenue</td>
              </tr>
              {/* <!-- row 2 --> */}
              <tr>
                <th>2</th>
                <td>Earthing system & Light Fixtures Installation</td>
                <td>Energypac</td>
                <td>25, Energy Center, Tejgaon I/A, Dhaka - 1208</td>
              </tr>
              {/* <!-- row 3 --> */}
              <tr>
                <th>3</th>
                <td>Lighting Protection System installation</td>
                <td>Rocket Builders Ltd</td>
                <td>Savar</td>
              </tr>
              {/* <!-- row 4 --> */}
              <tr>
                <th>4</th>
                <td>
                  Internal & External Wiring (Electrical, Telephone, IPTV& AV
                  system)
                </td>
                <td>Cross World</td>
                <td>Plot 1/B Rd 90, Dhaka 1212</td>
              </tr>
              {/* <!-- row 5 --> */}
              <tr>
                <th>5</th>
                <td>Supply of MDB, SDB& Final distribution board</td>
                <td>LATIF TOWER</td>
                <td>PABNA</td>
              </tr>
              {/* <!-- row 6 --> */}
              <tr>
                <th>6</th>
                <td>
                  Supply & Installation of Indoor, Outdoor light & other
                  accessories.
                </td>
                <td>NABILA FASHION HOUSE PROJECT</td>
                <td>BASHUNDHARA</td>
              </tr>
              {/* <!-- row 7 --> */}
              <tr>
                <th>7</th>
                <td>Total Earthing system</td>
                <td>ATM BUILDERS LTD</td>
                <td>TONGI, GAZIPUR</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* end section 3 */}

      {/* setion 4 */}

      <div className="md:mx-10 mx-20 my-5">
        <div className=" grid md:grid-cols-4 grid-cols-1 gap-4 ">
          <div>
            <img
              className="h-72 w-72 object-cover shadow-lg  border-2 border-gray-500"
              src="/images/slider1.jpeg"
              alt=""
            />
          </div>
          <div>
            <img
              className="h-72 w-72 object-cover shadow-lg  border-2 border-gray-500"
              src="/images/slider2.jpeg"
              alt=""
            />
          </div>
          <div>
            <img
              className="h-72 w-72 object-cover shadow-lg  border-2 border-gray-500"
              src="/images/slider3.jpeg"
              alt=""
            />
          </div>
          <div>
            <img
              className="h-72 w-72 object-cover shadow-lg  border-2 border-gray-500"
              src="/images/slider4.jpeg"
              alt=""
            />
          </div>
        </div>
      </div>

      {/* end section 4 */}

      {/* setion 5 */}
      <div className="my-5">
        <div className=" flex justify-center items-center mb-4">
          <h1 className=" text-black text-2xl uppercase my-4 font-bold">
            LIST OF MAJOR COMPLETED PROJECTS
          </h1>
        </div>
        <div className=" flex  justify-center items-center ">
          <div className="item-start flex flex-col pl-6">
            <p className="text-gray-700 font-medium text-lg">
              01. Zzz Knit Wears Ltd, Katgora, Ashulia, Dhaka.
            </p>
            <p className="text-gray-700 font-medium text-lg">
              02. Aj Knitwear Ltd. Kashimpur, Gazipur.
            </p>
            <p className="text-gray-700 font-medium text-lg">
              03. Union Feed Mill Ltd. Kashimpur, Gazipur
            </p>
            <p className="text-gray-700 font-medium text-lg">
              04. Amardesh Agro Ltd. Kashimpur, Gazipur.
            </p>
            <p className="text-gray-700 font-medium text-lg">
              05. Sonia Nurshing Home-2, Tangaile Sadar, Tangaile.
            </p>
            <p className="text-gray-700 font-medium text-lg">
              06. Tecl Abdul Latif Tower, Kushtia Sadar, Kushtia.
            </p>
            <p className="text-gray-700 font-medium text-lg">
              07. Latif Tower, Pabna.
            </p>
            <p className="text-gray-700 font-medium text-lg">
              08. Nibir Jewelers, Baitul Mukarram Market, Paltan, Dhaka.
              (project Fatullah).
            </p>
            <p className="text-gray-700 font-medium text-lg">
              09. Nabila Fashion House Project, Bashundhara.
            </p>
            <p className="text-gray-700 font-medium text-lg">
              10. Atm Builders Ltd. Aouchpara, Tongi, Gazipur.
            </p>
            <p className="text-gray-700 font-medium text-lg">
              11. East West Property Development Ltd. Bashundhara Residential
              Area Baridhara, Dhaka.
            </p>
            <p className="text-gray-700 font-medium text-lg">
              12. Greenvale Sweaters Ltd. 90,l.k Plaza Dakshinkhan Bazzar.
            </p>
            <p className="text-gray-700 font-medium text-lg">
              13. Green Warehouse, British American Tobacco, Kustia.
            </p>
            <p className="text-gray-700 font-medium text-lg">
              14. Nasir Glassware & Tube Industries Ltd. 85, Suhrawardi Avenue
              Baridhara, Dhaka-1212.
            </p>
            <p className="text-gray-700 font-medium text-lg">
              15. Ahsania Mission Cancer & General Hospital. Abc.
            </p>
            <p className="text-gray-700 font-medium text-lg">
              17. Royal Saudi Embassy Dhaka, Charuta Private Ltd. Mirpur 10,
              Dhaka 1216.
            </p>
            <p className="text-gray-700 font-medium text-lg">
              18. Nordic Embassy Dhaka. Haruta Private Ltd. Mirpur 10, Dhaka
              1216.
            </p>
            <p className="text-gray-700 font-medium text-lg">
              19. Dhaka Club, Abc, 1, Maulana Abdul Hamid Khan Bhashani Road,
              Shahbag, Dhaka - 1000.
            </p>
            <p className="text-gray-700 font-medium text-lg">
              20. British High Commssion, Ajax Project, Charuta Private Ltd.
            </p>
            <p className="text-gray-700 font-medium text-lg">
              21. Fashion Accessories, 20 Astha Bhaban 4th Floor, Section-06,
              Mirpur 10, Dhaka 1216.
            </p>
            <p className="text-gray-700 font-medium text-lg">
              22. Doreen Development Ltd. 21-23, Kazi Nazrul Islam Avenue,
              Walsow Tower, Dhaka 1000.
            </p>
            <p className="text-gray-700 font-medium text-lg">
              23. Kirti Holdings Ltd. 5 (2nd Floor),
              Road-17,block-d,banani,dhaka-1213.
            </p>
            <p className="text-gray-700 font-medium text-lg">
              24. Finery And Rhymer Apparel Ltd. Darus Salam Mirpur.
            </p>
            <p className="text-gray-700 font-medium text-lg">
              25. Hexagon, Level-4, Sel Huq Skypark, 23/2 Mirpur Rd, Shyamoli,
              1207 Dhaka.
            </p>
          </div>
        </div>
      </div>

      {/* section 5 end */}

      <Footer />
    </div>
  );
}
