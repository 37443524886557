import React from "react";
import Footer from "../Footer";
import MobileNavbar from "../MobileNavbar";
import Navbar from "../Navbar";

export default function Products() {
  return (
    <div>
      <div>
        <div className="hidden lg:block">
          <Navbar />
        </div>
        <div className="block lg:hidden">
          <MobileNavbar />
        </div>
      </div>

      {/* products */}

      {/* section 1 */}
      <div className="my-10 mx-10">
        <h1 className="text-black text-2xl font-bold my-5">TRANSFORMER</h1>
        <p className="mb-5">
          BDES Manufactures, Assembles and Engaged in Marketing of electrical
          substation equipment, Power Transformer range from 5MVA to 10 MVA.
        </p>
        <div className="flex justify-center items-center">
          <img src="/images/transformer1.jpg" alt="transformer" />
        </div>
        <div className=" grid gap-4 md:grid-cols-5 grid-cols-2 md:mx-[100px] md:my-10 my-5">
          <div>
            <img className="h-8 w-20" src="/images/togami.png" alt="togami" />
          </div>
          <div>
            <img className="h-8 w-20" src="/images/dorman.png" alt="dorman" />
          </div>
          <div>
            <img className="h-8 w-20" src="/images/abb.png" alt="abb" />
          </div>
          <div>
            <img className="h-8 w-20" src="/images/beluk.jpg" alt="beluk" />
          </div>
          <div>
            <img className="h-8 w-19" src="/images/ducati.png" alt="ducati" />
          </div>
        </div>
        {/* end transformer */}
        <h1 className="text-black text-2xl font-bold my-5">HT PANEL</h1>
        <div className=" flex md:flex-row md:justify-between flex-col mb-5 ">
          <div className="md:w-[500px]">
            BDES makes own Factory assembled 14 SWG Sheet steel clad, dust and
            vermin proof free standing floor mounting, with heat proof/powder
            quoted painted 11 KV & 33KV, 50Hz, 3 phase, indoor or outdoor type
            high-tension switchgears having hard drawn electrolytic copper
            busbar and other protection relays & metering equipment.
          </div>
          <div>
            <img
              className="w-72 h-44 mb-5"
              src="/images/htpanel.jpg"
              alt="htpanel"
            />
          </div>
        </div>
        {/* end ht panel */}
        <h1 className="text-black text-2xl font-bold my-5">LT PANEL</h1>
        <div className=" flex md:flex-row md:justify-between flex-col mb-5">
          <div className="md:w-[500px]">
            BDES makes sheet steel clad, dust and vermin proof, free standing
            floor mounting type Low-Tension Switch-gears for 3-pharse, 4-wire,
            415/240V system with TPN hard drawn electrolytic copper busbars &
            equipped with suitable circuit breakers, protective relays,
            measuring equipment.
          </div>
          <div>
            <img
              className="w-72 h-44 mb-5"
              src="/images/ltpanel.jpg"
              alt="htpanel"
            />
          </div>
        </div>
        {/* end lt panel */}
        <h1 className="text-black text-2xl font-bold my-5">GENERATOR</h1>
        <div className=" flex justify-center md:flex-row flex-col space-x-5 mb-5">
          <img className="h-72 w-72" src="/images/generator1.png" alt="gen1" />
          <img className="h-72 w-72" src="/images/generator2.jpg" alt="gen2" />
        </div>
        <p className="my-5">
          BDES deals with world famous brands of generating sets. Currently BDES
          is authorized to sell the generator of "T & R Generators" of U.K.
          Inmesol of Spain and Leega of China. Capacity range from I KW to 2 MW
          at both fifty and sixty cycle operation single and three phases. The
          generating set can be deployed either as prime or standby unit.
        </p>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
          <p className=" text-gray-800 text-lg ">Static or Mobile.</p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
          <p className=" text-gray-800 text-lg ">Petrol, Diesel or Gas.</p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
          <p className=" text-gray-800 text-lg ">Manual and automatic start.</p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
          <p className=" text-gray-800 text-lg ">
            Single and multi set operation.
          </p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
          <p className=" text-gray-800 text-lg ">
            Sound attenuated and waterproof canopies.
          </p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
          <p className=" text-gray-800 text-lg ">
            On-highway, off- highway rental and construction side design.
          </p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
          <p className=" text-gray-800 text-lg ">
            Open, Frame, Key-switch operation to full auto synchronization.
          </p>
        </div>
        <div className=" grid gap-4 md:grid-cols-4 grid-cols-2 md:mx-[100px] md:my-10">
          <div>
            <img className="h-10 w-20" src="/images/tr.jpg" alt="tr" />
          </div>
          <div>
            <img
              className="h-10 w-20"
              src="/images/inmesol.png"
              alt="inmesol"
            />
          </div>
          <div>
            <img className="h-10 w-20" src="/images/leega.png" alt="leega" />
          </div>
          <div>
            <img
              className="h-10 w-20"
              src="/images/ricardo.png"
              alt="ricardo"
            />
          </div>
        </div>

        {/* end generator */}
        <h1 className="text-black text-2xl font-bold my-5">
          BUS BAR TRUNKING SYSTEM
        </h1>
        <div className=" flex md:justify-center md:flex-row flex-col space-x-5 mb-5 ">
          <img
            className="h-96 w-96 mb-4 ml-5 md:ml-0"
            src="/images/busbar2.jpg"
            alt="busbar"
          />
          <img className="h-96 w-96" src="/images/busbar1.jpg" alt="busbar" />
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
          <p className=" text-gray-800 text-lg ">25A-40A Lighting Bus-bars</p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
          <p className=" text-gray-800 text-lg ">63A-160A Low Power Bus-bar</p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
          <p className=" text-gray-800 text-lg ">
            160A-1000A Plug-in Distribution Bus-bar
          </p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
          <p className=" text-gray-800 text-lg ">800A-6300A Power Bus-bar</p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
          <p className=" text-gray-800 text-lg ">
            {" "}
            800A-6300A Flexible terminals
          </p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
          <p className=" text-gray-800 text-lg ">
            800A-3200 A-7.3 to 33 kV Medium Voltage Bus-bar
          </p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
          <p className=" text-gray-800 text-lg ">50A-300A Trolley Bus-bar</p>
        </div>
        <p className="my-5">
          BDES is representing Graziadio/ Megabarre brand BBT in Bangladesh.
          Graziadio/ Magabarre is manufacturing BBT in different factories in
          Italy. Both these companies export more than 35 counties in the Globe.
          They produce both Copper and Aluminum BBT products. The following
          products are available for your needs.
        </p>
        <div className=" flex justify-center md:flex-row flex-col space-x-5 mb-5">
          <img
            className="h-24 w-96"
            src="/images/graziadio.png"
            alt="graziadio"
          />
          <img
            className="h-24 w-96"
            src="/images/megabarre.png"
            alt="megabarre"
          />
        </div>
      </div>
      {/* end section 1 */}

      <Footer />
    </div>
  );
}
