import React from "react";
import Footer from "../Footer";
import MobileNavbar from "../MobileNavbar";
import Navbar from "../Navbar";

export default function HealthSafetyWelfare() {
  const bgBanner1 = "/images/health.jpg";
  return (
    <div>
      <div>
        <div className="hidden lg:block">
          <Navbar />
        </div>
        <div className="block lg:hidden">
          <MobileNavbar />
        </div>
      </div>

      {/* section 1 */}

      <div
        style={{
          backgroundImage: `url(${bgBanner1})`,
          backgroundSize: "cover",
        }}
        className="h-96 w-full flex justify-center items-center "
      >
        <h1 className=" text-white font-bold md:text-4xl text-2xl uppercase">
          HEALTH SAFETY AND WELFARE
        </h1>
      </div>

      {/* end section 1 */}

      {/* section 2 */}

      <div className="my-10 mx-16 flex justify-center items-center flex-col md:items-start">
        <p className=" text-gray-800 text-lg mb-4">
          This statement recognizes BD Electrical Services obligations and
          accepts its responsibility as an employer for providing a safe and
          healthy working environment on premises and property under its
          control. It is the policy of BD Electrical Services operate working
          practices which make proper provision for the health, safety and
          welfare at work for employees and anyone else that may be affected by
          our operations.
        </p>
        <p className=" text-gray-800 text-lg mb-4">
          BD Electrical Services in the conduct of its activities will ensure
          that it:
        </p>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-8  w-4 bg-gray-700"></div>
          <p className=" text-gray-800 text-lg ">
            Protects the health, safety and welfare of its employees and others
            who may be affected by its activities.
          </p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-8 w-4 bg-gray-700"></div>
          <p className=" text-gray-800 text-lg ">
            Limits adverse effects on and adjacent to the area in which those
            activities are carried out.
          </p>
        </div>
      </div>

      {/* end section 2 */}
      <Footer />
    </div>
  );
}
