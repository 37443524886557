import React from "react";
import Footer from "../Footer";
import MobileNavbar from "../MobileNavbar";
import Navbar from "../Navbar";

export default function Md() {
  return (
    <div>
      <div>
        <div className="hidden lg:block">
          <Navbar />
        </div>
        <div className="block lg:hidden">
          <MobileNavbar />
        </div>
      </div>

      {/* section 1 */}
      <div className="mx-10">
        <div className="flex justify-center items-center my-8">
          <h1 className=" text-black font-bold text-2xl">MANAGING DIRECTOR</h1>
        </div>
        <div className="flex md:flex-row md:space-x-5 mb-4 md:justify-between flex-col">
          <div className="">
            <p>
              First of all my sincere gratitude & thanks to the almighty Allah,
              BD Electrical Services is certified by BSTI as a distribution
              transformer manufacturer company in Bangladesh. We are roaming all
              over the country. We are committed to the best quality & service
              to the door of our valued customers. We maintain to provide our
              products & services in committed time. Commitment is our capital &
              customer's satisfaction is our best priority. We are improving our
              products & services quality day by day. I would like to express my
              heartiest greetings to all my customers, employee & well-wishers.
            </p>
          </div>
          <img src="/images/md.jpg" alt="md" className="w-44 md:h-32 h-44" />
        </div>

        {/* end first row */}
        {/* second row */}
        <div className=" flex md:items-start flex-col space-y-1 my-5">
          <p className="text-black font-bold text-lg">Dr. Riffat Sharmin </p>
          <p>BD ELECTRICAL SERVICES</p>
        </div>

        {/* end of second row */}
      </div>

      <Footer />
    </div>
  );
}
