import React from "react";
import Footer from "../Footer";
import MobileNavbar from "../MobileNavbar";
import Navbar from "../Navbar";

export default function EqualOpportunityPolicy() {
  const bgBanner1 = "/images/equal.jpg";
  return (
    <div>
      <div>
        <div className="hidden lg:block">
          <Navbar />
        </div>
        <div className="block lg:hidden">
          <MobileNavbar />
        </div>
      </div>

      {/* section 1 */}

      <div
        style={{
          backgroundImage: `url(${bgBanner1})`,
          backgroundSize: "cover",
        }}
        className="h-96 w-full flex justify-center items-center "
      >
        <h1 className=" text-white font-bold md:text-4xl text-2xl uppercase">
          EQUAL OPPORTUNITY POLICY
        </h1>
      </div>

      {/* end section 1 */}

      {/* section 2 */}

      <div className="my-10 mx-16 flex justify-center items-center flex-col">
        <p className=" text-gray-800 text-lg mb-4">
          BD Electrical Services is an equal Opportunity employer. No applicant
          or employee receives less favorable treatment on racial, ethnic,
          sexual, disability or religious grounds.
        </p>
        <p className=" text-gray-800 text-lg mb-4">
          BD Electrical Services is committed to providing a working environment
          that is free from discrimination. Therefore, the company will try to
          ensure that no potential or actual member of staff will receive less
          favorable treatment on the grounds of ethnic origin, color, gender,
          disability, marital status, age, sexuality, or religion.
        </p>
        <p className=" text-gray-800 text-lg mb-4">
          BD Electrical Services undertakes to review periodically its selection
          criteria and procedures to maintain a system where individuals are
          selected, promoted and treated solely on the basis of their merits and
          abilities.
        </p>
        <p className=" text-gray-800 text-lg mb-4">
          Due to the nature of installation work, applicants for site staff with
          certain disabilities are unable to be considered. However,
          applications for all other areas are welcomed.
        </p>
      </div>

      {/* end section 2 */}
      <Footer />
    </div>
  );
}
