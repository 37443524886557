import React from "react";
import Footer from "../Footer";
import MobileNavbar from "../MobileNavbar";
import Navbar from "../Navbar";

export default function Td() {
  return (
    <div>
      <div>
        <div className="hidden lg:block">
          <Navbar />
        </div>
        <div className="block lg:hidden">
          <MobileNavbar />
        </div>
      </div>

      {/* section 1 */}
      <div className="mx-10">
        <div className="flex justify-center items-center my-8">
          <h1 className=" text-black font-bold text-2xl">TECHNICAL DIRECTOR</h1>
        </div>
        <div className="flex md:flex-row md:space-x-5 mb-4 md:justify-between flex-col">
          <div className="">
            <p>
              BD Electrical Services has a big team to support any kind of
              Electrical solution. We do electrical Design, Supply diesel
              generator, sub station Installation, Commissioning and Maintenance
              of LV, MV, HV, & EHV Electrical system Up to 400kV. To facilitate
              more to you, BD Electrical Services has established ahighly
              integrated factory where we produce any size of power Transformers
              and switchgears with 33Kv Max. voltage. We have vast experience in
              complete project implementation in small project and in bigger
              project. BD Electrical Services is Certified Company. The desire
              of BD Electrical Services has always been the customer
              satisfaction. This is our pride that customers provide us repeated
              orders which shows we are on the right track on satisfying our
              valuable customers.
            </p>
          </div>
          <img src="/images/td.jpg" alt="ceo" className="w-44 md:h-32 h-44" />
        </div>

        {/* end first row */}
        {/* second row */}
        <div className=" flex md:items-start flex-col space-y-1 my-5">
          <p className="text-black font-bold text-lg">
            MD. AZADUL ISLAM (Eng){" "}
          </p>
          <p>Technical Director</p>
          <p>M/S BD ELECTRICAL SERVICES</p>
        </div>

        {/* end of second row */}
      </div>

      <Footer />
    </div>
  );
}
