import React from "react";
import { Link } from "react-router-dom";

export default function Navbar() {
  return (
    <div className="  bg-gray-800 h-16 w-full md:flex md:flex-row md:space-x-12 md:mx-auto md:items-center md:pl-8">
      <Link to="/">
        <p className="text-white text-xs uppercase font-medium hover:text-orange-500">
          Home
        </p>
      </Link>
      <Link to="/aboutus">
        <p className="text-white text-xs uppercase font-medium hover:text-orange-500">
          About Us
        </p>
      </Link>

      <div className="menu menu-horizontal">
        <li tabIndex={0}>
          <p className="text-white text-xs uppercase font-medium hover:text-orange-500 ">
            TEAM
          </p>
          <ul className="rounded-box bg-gray-600 p-2">
            <li>
              <Link to="/team/chairman">
                <a className="text-white">MESSAGE FROM CHAIRMAN</a>
              </Link>
            </li>
            <li>
              <Link to="/team/ceo">
                <a className="text-white">CHIEF EXECUTIVE OFFICER (CEO)</a>
              </Link>
            </li>
            <li>
              <Link to="/team/md">
                <a className="text-white">MANAGING DIRECTOR</a>
              </Link>
            </li>
            <li>
              <Link to="/team/td">
                <a className="text-white">TECHNICAL DIRECTOR</a>
              </Link>
            </li>
            <li>
              <Link to="/team/key-personnel">
                <a className="text-white">KEY PERSONNEL</a>
              </Link>
            </li>
          </ul>
        </li>
      </div>
      <Link to="/products">
        <p className="text-white text-xs uppercase font-medium hover:text-orange-500">
          Products
        </p>
      </Link>
      <div className="menu menu-horizontal">
        <li tabIndex={0}>
          <p className="text-white text-xs uppercase font-medium hover:text-orange-500 ">
            EXPERTISE
          </p>
          <ul className="rounded-box bg-gray-600 p-2">
            <li>
              <Link to="/expertise/electrical-engineering">
                <a className="text-white">ELECTRICAL ENGINEERING</a>
              </Link>
            </li>
            <li>
              <Link to="/expertise/automation-instrumentation-work">
                <a className="text-white">AUTOMATION & INSTRUMENTATION WORKS</a>
              </Link>
            </li>
            <li>
              <Link to="/expertise/civil-engineering">
                <a className="text-white">CIVIL ENGINEERING</a>
              </Link>
            </li>
            <li>
              <Link to="/expertise/mechanical-engineering">
                <a className="text-white">MECHANICAL ENGINEERING</a>
              </Link>
            </li>
          </ul>
        </li>
      </div>
      <div className="menu menu-horizontal">
        <li tabIndex={0}>
          <p className="text-white text-xs uppercase font-medium hover:text-orange-500 ">
            Our Services
          </p>
          <ul className="rounded-box bg-gray-600 p-2">
            <li>
              <Link to="/services/commercial">
                <a className="text-white">COMMERCIAL</a>
              </Link>
            </li>
            <li>
              <Link to="/services/industrial">
                <a className="text-white">INDUSTRIAL</a>
              </Link>
            </li>
            <li>
              <Link to="/services/residentail">
                <a className="text-white">RESIDENTIAL</a>
              </Link>
            </li>
            <li>
              <Link to="/services/substation">
                <a className="text-white">SUBSTATION</a>
              </Link>
            </li>
          </ul>
        </li>
      </div>
      <Link to="/projects">
        <p className="text-white text-xs uppercase font-medium hover:text-orange-500">
          Projects
        </p>
      </Link>
      <div className="menu menu-horizontal">
        <li tabIndex={0}>
          <p className="text-white text-xs uppercase font-medium hover:text-orange-500 ">
            Policy
          </p>
          <ul className="rounded-box bg-gray-600 p-2">
            <li>
              <Link to="/policy/equal-opportunity">
                <a className="text-white">EQUAL OPPORTUNITY POLICY</a>
              </Link>
            </li>
            <li>
              <Link to="/policy/health-safety-welfare">
                <a className="text-white">HEALTH SAFETY AND WELFARE</a>
              </Link>
            </li>
            <li>
              <Link to="/policy/environment">
                <a className="text-white">ENVIRONMENTAL POLICY</a>
              </Link>
            </li>
          </ul>
        </li>
      </div>
      <Link to="/customer-care">
        <p className="text-white text-xs uppercase font-medium hover:text-orange-500">
          customer care
        </p>
      </Link>
      <Link to="/contactus">
        <p className="text-white text-xs uppercase font-medium hover:text-orange-500">
          Contact Us
        </p>
      </Link>
    </div>
  );
}
