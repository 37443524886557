import React from "react";

export default function Footer() {
  return (
    <div className="flex flex-col">
      <div>
        <div className="md:w-full bg-gray-700 md:flex md:flex-row md:justify-between p-6">
          <div>
            <p className="text-white uppercase text-xl font-semibold mb-3">
              BD Electrical Services
            </p>
            <p className=" w-52 text-sm text-gray-400">
              BD Electrical Services is a Bangladeshi multinational
              headquartered in Dhaka, Bangladesh with operations in the
              technology, engineering, construction, power and energy sector.
            </p>
          </div>
          <div>
            <h3 className="text-gray-400 text-xs uppercase mb-3">
              ADDITIONAL LINKS
            </h3>
            <div className=" space-y-1 flex flex-col">
              <a
                className="text-gray-400 hover:text-orange-500 text-sm"
                href="#"
              >
                Latest Company News
              </a>
              <a
                className="text-gray-400 hover:text-orange-500 text-sm"
                href="#"
              >
                Products
              </a>
              <a
                className="text-gray-400 hover:text-orange-500 text-sm"
                href="#"
              >
                Industry
              </a>
              <a
                className="text-gray-400 hover:text-orange-500 text-sm"
                href="#"
              >
                Resources
              </a>
              <a
                className="text-gray-400 hover:text-orange-500 text-sm"
                href="#"
              >
                Get in touch
              </a>
            </div>
          </div>
          <div>
            <h3 className="text-gray-400 text-xs uppercase mb-3">
              CONTACT INFO
            </h3>
            <p className=" w-40 text-gray-400 text-sm mb-3">
              House: Hasna Monjil Holding No: G P TA 39/1 Moddho, Gulshan Badda
              Link Rd, Dhaka 1212
            </p>
            <div className="flex flex-col space-x-0.5">
              {/* <p className=" text-gray-400 text-sm">
                Email: marketing@bdelectricalservices.com.bd
              </p> */}
              <p className=" text-gray-400 text-sm">
                Email: info@bdelectricalservices.com
              </p>
              <p className=" text-gray-400 text-sm">
                Phone: +88 01791001818, +88 01718956239
              </p>
            </div>
          </div>
          <div>
            <h3 className="text-white text-xs uppercase mb-3">
              SUBSCRIBE NEWSLETTER
            </h3>
            <div className="w-52 border border-1 border-gray-400 h-8 mb-2">
              <input
                className=" placeholder:text-gray-400 text-sm pl-3 bg-transparent focus:outline-none "
                type="text"
                placeholder="Enter Your Email"
              />
            </div>
            <button className="text-sm text-gray-600 bg-white border border-1 border-white focus:outline-none hover:bg-slate-600 hover:text-white px-4 py-1 ">
              subscribe
            </button>
          </div>
        </div>
      </div>
      <div className="pl-6 flex flex-row space-x-4 bg-gray-700 pb-3">
        <a
          className="text-white text-2xl font-extrabold hover:text-blue-800"
          href="#"
        >
          in
        </a>
        <a
          className="text-white text-2xl font-extrabold hover:text-blue-800"
          href="#"
        >
          f
        </a>
      </div>
      <div className=" w-full bg-black">
        <div className="md:flex md:flex-row md:justify-between md:px-20 flex flex-col space-y-2 my-4">
          <div className="md:pt-1 flex justify-center items-center">
            <p className="text-sm text-gray-400 hover:text-orange-500">
              Privacy Policy / Terms & Condition
            </p>
          </div>
          <div className="flex justify-center items-center">
            <img
              className=" h-20 w-44 "
              src="/images/footer.jpg"
              alt=""
              srcset=""
            />
          </div>
          <div className="md:pt-6">
            <p className=" text-gray-400 text-sm text-center">
              © Copyright 2022 Reserved by BD Electrical Services.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
