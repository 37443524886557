import React from "react";
import Footer from "../Footer";
import MobileNavbar from "../MobileNavbar";
import Navbar from "../Navbar";

export default function MessageFromChairman() {
  return (
    <div>
      <div>
        <div className="hidden lg:block">
          <Navbar />
        </div>
        <div className="block lg:hidden">
          <MobileNavbar />
        </div>
      </div>
      <div className="mx-10">
        {/* first row */}
        <div className="flex justify-center items-center my-8">
          <h1 className=" text-black font-bold text-2xl">
            MESSAGE FROM CHAIRMAN
          </h1>
        </div>
        <div className="flex md:flex-row md:space-x-5 mb-4 md:justify-between flex-col">
          <div className="">
            <p>
              As the country is growing, there have been a large demand of
              detailed engineered solution. We understood that there are many
              kinds of deification in power sector so to fill that deifications
              in the market. We made "BD Electrical Services” for you.
              Consisting of many skilled Engineers technicians, BD Electrical
              Services has a big team to support any kind of Electrical
              solution. We do electrical Design, Supply diesel generator, sub
              station Installation,Commissioning and Maintenance of LV, MV, HV,
              & EHV Electrical system Up to 400kV. To facilitate more to you, BD
              Electrical Services has established a highly integrated factory
              where we produce any size of power Transformers and switchgears
              with 33kV Max. voltage. As a largest Engineering Procurement
              Construction Company (EPC) in Bangladesh, we are delivering high
              quality fast paced power & energy support solutions. With a wealth
              of in-depth knowledge and expertise, our teams deliver a reliable
              and compliant service to every business process. We have vast
              experience in complete project implementation in small project and
              in bigger project. BD Electrical Services is Certified Company.
              The desire of BD Electrical Services has always been the customer
              satisfaction. This is our pride that customers provide us repeated
              orders which shows we are on the right track on satisfying our
              valuable customers.
            </p>
          </div>
          <img
            className="w-44 h-62"
            src="/images/chairman.jpg"
            alt="chairman"
          />
        </div>

        {/* end first row */}

        {/* second row */}
        <div className=" flex md:items-end flex-col space-y-1 my-5">
          <p className="text-black font-bold text-lg">Md. Jahangir Hossain</p>
          <p>Chairman</p>
          <p>BD Electrical Services</p>
          <p>Ex. Chief Engineer System Operation & SCADA, DPDC</p>
          <p>B.Sc. Engg (Machanical), BUET</p>
        </div>

        {/* end of second row */}

        {/* Curriculum Vitae */}
        <div className="flex justify-center items-center my-8">
          <h1 className=" text-black font-bold text-2xl uppercase">
            Curriculum Vitae
          </h1>
        </div>
        <div className=" flex justify-center items-center ">
          <div className=" flex flex-row space-x-10 mb-2 text-left">
            <div>
              <h3 className=" text-lg font-semibold">Name</h3>
              <h3 className=" text-lg font-semibold w-44">Father’s Name</h3>
              <h3 className=" text-lg font-semibold">Mother’s Name</h3>

              <h3 className=" text-lg font-semibold">Date of Birth</h3>
              <h3 className=" text-lg font-semibold h-24">Present Address</h3>
              <h3 className=" text-lg font-semibold h-24">Permanent Address</h3>
              <h3 className=" text-lg font-semibold mt-4">Contract Number</h3>
            </div>
            <div>
              <p className=" text-lg font-semibold">: Md. Jahangir Hossain</p>
              <p className=" text-lg font-semibold">: Late Razzab Ali</p>
              <p className=" text-lg font-semibold">: Late Surjaban Bibi</p>
              <p className=" text-lg font-semibold">: 31/12/1953</p>
              <p className=" text-lg font-semibold w-48">
                : House no 85, Road no 8/A, Dhanmondi R/A , Dhaka-1209.
              </p>
              <p className=" text-lg font-semibold w-48 mt-3">
                : Village : Mngshair, PO: Darora, PS:Muadnagar, Dist: Comilla.
              </p>
              <p className=" text-lg font-semibold">: 01859564488</p>
            </div>
          </div>
        </div>

        {/* end info */}
        <div className="my-8">
          <h1 className=" text-black font-bold text-2xl uppercase">
            Service record
          </h1>
          <div className="overflow-x-auto my-5">
            <table className="table w-full">
              {/* <!-- head --> */}
              <thead>
                <tr>
                  <th>Organization</th>
                  <th>Designation</th>
                  <th>From</th>
                  <th>To</th>
                  <th>Total Period</th>
                </tr>
              </thead>
              <tbody>
                {/* <!-- row 1 --> */}
                <tr>
                  <td>BSFIC</td>
                  <td>Assistant Engineer</td>
                  <td>08/06/1977</td>
                  <td>06/08/1978</td>
                  <td>01 Year</td>
                </tr>
                {/* <!-- row 2 --> */}
                <tr>
                  <td>BPDB</td>
                  <td>Assistant Engineer</td>
                  <td>13/09/1978</td>
                  <td>13/09/1982</td>
                  <td>04 Years</td>
                </tr>
                {/* <!-- row 3 --> */}
                <tr>
                  <td>BPDB</td>
                  <td>Sub-divisional Engineer</td>
                  <td>14/09/1982</td>
                  <td>30/09/1991</td>
                  <td>09 Years 05 Months</td>
                </tr>
                {/* <!-- row 4 --> */}
                <tr>
                  <td>DESA</td>
                  <td>Sub-divisional Engineer</td>
                  <td>01/10/1991</td>
                  <td>30/05/1992</td>
                  <td>08 Months</td>
                </tr>
                {/* <!-- row 5 --> */}
                <tr>
                  <td>DESA</td>
                  <td>Executive Engineer</td>
                  <td>31/05/1992</td>
                  <td>02/03/2007</td>
                  <td>14 Years 10 Months</td>
                </tr>
                {/* <!-- row 6 --> */}
                <tr>
                  <td>DESA</td>
                  <td>Superintending Engineer</td>
                  <td>03/03/2007</td>
                  <td>30/06/2008</td>
                  <td>01 Years 03 Months</td>
                </tr>
                {/* <!-- row 7 --> */}
                <tr>
                  <td>DPDC</td>
                  <td>Deputy General Manager, System Protection & Services</td>
                  <td>01/07/2008</td>
                  <td>26/08/2012</td>
                  <td>04 Years 02 months</td>
                </tr>
                {/* <!-- row 8 --> */}
                <tr>
                  <td>DPDC</td>
                  <td>Chief Engineer System Operation & SCADA</td>
                  <td>26/08/2012</td>
                  <td>30/12/2013</td>
                  <td>01 Year 03 months</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* end service */}
        <div className="my-8">
          <h1 className=" text-black font-bold text-2xl uppercase">
            education
          </h1>
          <div className="overflow-x-auto my-5">
            <table className="table w-full">
              {/* <!-- head --> */}
              <thead>
                <tr>
                  <th>Certificate/Exam</th>
                  <th>Year</th>
                  <th>Result/Division/ Class/CGPA</th>
                  <th>Board/University</th>
                </tr>
              </thead>
              <tbody>
                {/* <!-- row 1 --> */}
                <tr>
                  <td>S.S.C</td>
                  <td>1969</td>
                  <td>1st Division</td>
                  <td>Comilla Education Board</td>
                </tr>
                {/* <!-- row 2 --> */}
                <tr>
                  <td>H.S.C</td>
                  <td>1972</td>
                  <td>1st Division</td>
                  <td>Comilla Education Board</td>
                </tr>
                {/* <!-- row 3 --> */}
                <tr>
                  <td>Bachelor of Science in Engineering (Mechanical)</td>
                  <td>1977</td>
                  <td>Second Class</td>
                  <td>
                    Bangladesh University of Engineering & Technology (BUET)
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* end education */}

        <div className="my-8">
          <h1 className=" text-black font-bold text-2xl uppercase my-5">
            Working Experience
          </h1>
          <div className=" flex flex-row space-x-4 mb-4">
            <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
            <p className=" text-gray-800 text-lg ">
              Letter of honour has been awarded by Managing Director, DPDC for
              successful repairing of Distribution Transformer.
            </p>
          </div>
          <div className=" flex flex-row space-x-4 mb-4">
            <div className="md:h-18  w-4 rounded-full bg-gray-700 mt-2"></div>
            <p className=" text-gray-800 text-lg ">
              Worked as Chief Engineer, System Operation & SCADA under Dhaka
              Power Distribution Company Ltd (DPDC) from August 2012 to December
              2013 and entrusted with the operation of the electrical network of
              DPDC ranging from 132kv to downwards. It includes operation and
              maintenance of 132/33kV, 33/11kV S/S, 132 kV UG/OH lines and 33kV
              UG/OH lines.
            </p>
          </div>
          <div className=" flex flex-row space-x-4 mb-4">
            <div className="md:h-18  w-4 rounded-full bg-gray-700 mt-2"></div>
            <p className=" text-gray-800 text-lg ">
              Worked as Deputy General Manager (Technical) System Protection &
              Services under Dhaka Power Distribution Company Ltd (DPDC) since
              July’2008 to August 2012 and entrusted with the repairing of
              damaged distribution transformers, repair of 33 KV and 11 KV
              underground cable, checking of consumer’s meter and feeder meters.
            </p>
          </div>
          <div className=" flex flex-row space-x-4 mb-4">
            <div className="md:h-10  w-4 rounded-full bg-gray-700 mt-2"></div>
            <p className=" text-gray-800 text-lg ">
              Worked as Superintending Engineer, Narayangonj circle under DESA
              from March/07 to June/08 and entrusted with the monitoring of
              electrical network under Narayangonj area and collection of
              revenues.
            </p>
          </div>
          <div className=" flex flex-row space-x-4 mb-4">
            <div className="md:h-18  w-4 rounded-full bg-gray-700 mt-2"></div>
            <p className=" text-gray-800 text-lg ">
              Worked as Deputy Director from Feb’2003 to March’2007 in Planning
              & Development Dte. under Dhaka Electric Supply Authority and
              entrusted with the preparation of executive summary of DESA,
              preparation of reply or queries of National Assembly, preparation
              of technical papers on behalf of DESA for ministry as well
              development partners.
            </p>
          </div>
          <div className=" flex flex-row space-x-4 mb-4">
            <div className="md:h-10  w-4 rounded-full bg-gray-700 mt-2"></div>
            <p className=" text-gray-800 text-lg ">
              Worked as Executive Engineer in Commercial Operation Division,
              Satmasjid under DESA from Nov’2002 to Feb’2003 and entrusted with
              billing, collection of revenues etc.
            </p>
          </div>
          <div className=" flex flex-row space-x-4 mb-4">
            <div className="md:h-18  w-4 rounded-full bg-gray-700 mt-2"></div>
            <p className=" text-gray-800 text-lg ">
              Worked as Deputy Director from Feb’94 to Nov’2002 in Planning &
              Development Dte. under Dhaka Electric Supply Authority and
              entrusted with the preparation of executive summary of DESA,
              preparation of reply or queries of National Assembly, preparation
              of technical papers on behalf of DESA for ministry as well
              development partners.
            </p>
          </div>
          <div className=" flex flex-row space-x-4 mb-4">
            <div className="md:h-10  w-4 rounded-full bg-gray-700 mt-2"></div>
            <p className=" text-gray-800 text-lg ">
              Worked as Executive Engineer from April’ 92 to Feb’94 in
              Commercial Operation Division under Dhaka Electric Supply
              Authority(DESA) and entrusted with commercial operation of
              Khilgoan and Postagola division.
            </p>
          </div>
          <div className=" flex flex-row space-x-4 mb-4">
            <div className="md:h-10  w-4 rounded-full bg-gray-700 mt-2"></div>
            <p className=" text-gray-800 text-lg ">
              Worked as Sub-divisional Engineer from March’89 to April’92 in
              Operation & Maintenance division, Satmasjid under Power
              Development Board and entrusted with operation & maintenance of
              electrical network of the above area.
            </p>
          </div>
          <div className=" flex flex-row space-x-4 mb-4">
            <div className="md:h-10  w-4 rounded-full bg-gray-700 mt-2"></div>
            <p className=" text-gray-800 text-lg ">
              Worked as Sub-divisional Engineer from Nov’86 to March’89 in High
              Tension Division in Power Development Board and entrusted with
              meter testing, meter reading, billing and collection of revenues
              etc.
            </p>
          </div>
          <div className=" flex flex-row space-x-4 mb-4">
            <div className="md:h-18  w-4 rounded-full bg-gray-700 mt-2"></div>
            <p className=" text-gray-800 text-lg ">
              Worked as Sub-divisional Engineer from Oct’82 to Nov’86 in Design
              & Inspection-II under Power Development Board and entrusted with
              the design of 132/33, 33/11kv s/s including preparation of
              specification of different substation equipment, preparation of
              tender document and evaluation of tender received etc.
            </p>
          </div>
          <div className=" flex flex-row space-x-4 mb-4">
            <div className="md:h-10  w-4 rounded-full bg-gray-700 mt-2"></div>
            <p className=" text-gray-800 text-lg ">
              Worked as Assistant Engineer from Oct’81 to Sept’82 in Power
              System Rehabilitation and Expansion Project under Power
              Development Board and entrusted with the preparation of
              specification of different power system equipment.
            </p>
          </div>
          <div className=" flex flex-row space-x-4 mb-4">
            <div className="md:h-10  w-4 rounded-full bg-gray-700 mt-2"></div>
            <p className=" text-gray-800 text-lg ">
              Worked as Assistant Engineer from Sep’78 to Sep’81 in Testing &
              commissioning Dte. under Power Development Board and entrusted
              with testing, commissioning of 132/33 kV, 33/11 kV substation
              throughout Bangladesh.
            </p>
          </div>
          <div className=" flex flex-row space-x-4 mb-4">
            <div className="md:h-10  w-4 rounded-full bg-gray-700 mt-2"></div>
            <p className=" text-gray-800 text-lg ">
              Worked as Mechanical Engineer from 08/06/1977 to 06/08/1978 in
              Rajshahi Sugar Mill under Bangladesh Sugar & Food Industries
              Corporation and entrusted with operation & maintenance of boiler,
              different types of pumps etc.
            </p>
          </div>
        </div>

        {/* end working experience */}
        <div className="my-8">
          <h1 className=" text-black font-bold text-2xl uppercase my-5">
            Training
          </h1>
          <div className=" flex flex-row space-x-4 mb-4">
            <div className="md:h-10  w-4 rounded-full bg-gray-700 mt-2"></div>
            <p className=" text-gray-800 text-lg ">
              Performed duty under supervision of a Polish Expert in 132 kV and
              33 kV Electric Substation from September 1978 to July 1979 under
              Bangladesh Power Development Board.
            </p>
          </div>
          <div className=" flex flex-row space-x-4 mb-4">
            <div className="md:h-10  w-4 rounded-full bg-gray-700 mt-2"></div>
            <p className=" text-gray-800 text-lg ">
              Successfully completed five week course on computer programming in
              COBOL from October 12 to November 11, 1983 conducted by Institute
              of Business Administration, University of Dhaka.
            </p>
          </div>
          <div className=" flex flex-row space-x-4 mb-4">
            <div className="md:h-10  w-4 rounded-full bg-gray-700 mt-2"></div>
            <p className=" text-gray-800 text-lg ">
              Completed a course of instruction in Electric Power Distribution
              Engineering from 20 March 1989 to 16 June 1989 provided by the
              British Government as a part of its British Technical Assistance
              Programme.
            </p>
          </div>
          <div className=" flex flex-row space-x-4 mb-4">
            <div className="md:h-10  w-4 rounded-full bg-gray-700 mt-2"></div>
            <p className=" text-gray-800 text-lg ">
              Successfully completed 13th Short Administrative Management Course
              commenced from 27th March to 09th April, 1994 under Bangladesh
              Public Administration Training Centre (PATC), Savar.
            </p>
          </div>
          <div className=" flex flex-row space-x-4 mb-4">
            <div className="md:h-10  w-4 rounded-full bg-gray-700 mt-2"></div>
            <p className=" text-gray-800 text-lg ">
              Successfully completed a training programme on computer hardware,
              assembling, maintenance and networking from 10th March to 10th
              April, 1999 under Electrical Division, IEB.
            </p>
          </div>
          <div className=" flex flex-row space-x-4 mb-4">
            <div className="md:h-10  w-4 rounded-full bg-gray-700 mt-2"></div>
            <p className=" text-gray-800 text-lg ">
              Completed a training programme on RDBMS Programming with ORACLE-8
              & Developer 2000 from 18th September to 8th December, 1999
              provided by The Institution of Engineers Bangladesh.
            </p>
          </div>
          <div className=" flex flex-row space-x-4 mb-4">
            <div className="md:h-10  w-4 rounded-full bg-gray-700 mt-2"></div>
            <p className=" text-gray-800 text-lg ">
              Successfully completed the training course on “Senior Management
              Course” commenced from 28th April 2010 to 5th May 2010 under the
              auspices of Training & Development wing, DPDC.
            </p>
          </div>
          <div className=" flex flex-row space-x-4 mb-4">
            <div className="md:h-10  w-4 rounded-full bg-gray-700 mt-2"></div>
            <p className=" text-gray-800 text-lg ">
              Successfully completed the training course on “Problem Solving,
              Decision Making and Conflict Resolution from 4th March 2012 to 6th
              March 2012 conducted under Power Sector Capacity Development
              Program arranged by MEMR.
            </p>
          </div>
        </div>

        {/* end Curriculum Vitae */}
      </div>

      <Footer />
    </div>
  );
}
