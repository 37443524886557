import React from "react";
import Footer from "../Footer";
import MobileNavbar from "../MobileNavbar";
import Navbar from "../Navbar";

export default function ElectricalEngineering() {
  const bgBanner1 = "/images/ec.jpg";
  return (
    <div>
      <div>
        <div className="hidden lg:block">
          <Navbar />
        </div>
        <div className="block lg:hidden">
          <MobileNavbar />
        </div>
      </div>

      {/* section 1 */}

      <div
        style={{
          backgroundImage: `url(${bgBanner1})`,
          backgroundSize: "cover",
        }}
        className="h-96 w-full flex justify-center items-center "
      >
        <h1 className=" text-white font-bold md:text-4xl text-2xl uppercase">
          ELECTRICAL ENGINEERING
        </h1>
      </div>

      {/* end section 1 */}

      {/* section 2 */}

      <div className="my-10 mx-16 flex justify-center items-center flex-col md:items-start">
        <p className=" text-gray-800 text-lg mb-4">
          BD Electrical Services is leading the future of electrification with
          advance technologies that distribute, protect and control. We provide
          customers, across various industries, with end-to end product and
          service solutions that ensure the reliability and protection of the
          electrical infrastructure; from the power station to the end point and
          all the sustainable power technology in between.
        </p>
        <p className=" text-gray-800 text-lg mb-4">
          We deliver advanced technologies that touch all parts of the
          electrical infrastructure - from the substation, to a facility's
          critical equipment, and all the power technologies in between. Our
          engagement with client starts at the design stage, advances through
          product installation and continues with ongoing service offerings
          designed to optimize the lifecycle of their equipment. The training,
          consulting and maintenance services that Industrial Solutions offers
          customers after installation enables them to get the most from their
          electrical and power systems.
        </p>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-8  w-4 bg-gray-700"></div>
          <p className=" text-gray-800 text-lg ">
            Electrical Consultants & turnkey contactors and A to Z electrical
            service provider.
          </p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-14 w-4 bg-gray-700 "></div>
          <p className=" text-gray-800 text-lg ">
            Supply, Installation, testing & commissioning of Electrical
            Utilities (Sub-stations, Power control center, MCC, Boiler
            equipment’s & water-treatment plant etc.)
          </p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-8 w-4 bg-gray-700 "></div>
          <p className=" text-gray-800 text-lg ">
            Drawing & design of Electrical systems for new, renovation/
            Re-modeling project.
          </p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-8 w-4 bg-gray-700 "></div>
          <p className=" text-gray-800 text-lg ">
            Design & Installation of Industrial, commercial & Domestic Power
            Distribution system, Lighting System together with statutory
            requirements.
          </p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-14 w-4 bg-gray-700 "></div>
          <p className=" text-gray-800 text-lg ">
            Manufacture of HT-LT panels, MCC & PCC panels, PFI, MDB, DB and any
            other panel board of various sizes including industrial lighting
            sets, cable duct etc.
          </p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-8 w-4 bg-gray-700 "></div>
          <p className=" text-gray-800 text-lg ">
            Maintenance, Preventive maintenance, Pre-construction services and
            procurement.
          </p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-8 w-4 bg-gray-700 "></div>
          <p className=" text-gray-800 text-lg ">
            Perform Energy Audit at Industrial & Commercial Power Distribution
            systems.
          </p>
        </div>
      </div>

      {/* end section 2 */}
      {/* section 3 */}
      <div className=" my-5 flex justify-center items-center">
        <h1 className=" text-black font-bold text-2xl my-5">
          SOME OF VALUABLE PROJECTS
        </h1>
      </div>
      <div className=" grid grid-cols-1 md:grid-cols-4 gap-4 md:mx-12 mx-24 my-5">
        <div>
          <img
            className=" h-72 w-72 shadow-lg border-2 border-gray-400"
            src="/images/ep1.jpg"
            alt="project1"
          />
        </div>
        <div>
          <img
            className=" h-72 w-72 shadow-lg border-2 border-gray-400"
            src="/images/ep2.jpg"
            alt="project1"
          />
        </div>
        <div>
          <img
            className=" h-72 w-72 shadow-lg border-2 border-gray-400"
            src="/images/ep3.jpg"
            alt="project1"
          />
        </div>
        <div>
          <img
            className=" h-72 w-72 shadow-lg border-2 border-gray-400"
            src="/images/ep4.jpg"
            alt="project1"
          />
        </div>
      </div>

      {/* section 3 end */}
      <Footer />
    </div>
  );
}
