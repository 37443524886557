import React from "react";
import Footer from "../Footer";
import MobileNavbar from "../MobileNavbar";
import Navbar from "../Navbar";

export default function Ceo() {
  return (
    <div>
      <div>
        <div className="hidden lg:block">
          <Navbar />
        </div>
        <div className="block lg:hidden">
          <MobileNavbar />
        </div>
      </div>

      {/* section 1 */}
      <div className="mx-10">
        <div className="flex justify-center items-center my-8">
          <h1 className=" text-black font-bold text-2xl">
            CHIEF EXECUTIVE OFFICER (CEO)
          </h1>
        </div>
        <div className="flex md:flex-row md:space-x-5 mb-4 md:justify-between flex-col">
          <div className="">
            <p>
              We are committed to providing the best quality to our valued
              customers. It was the customer support that made the company what
              it is today, and we at BD Electrical Services hope that customers
              will continue to provide such generous support and encouragement.
              BD Electrical Services will strive not only to meet the
              expectations of our customers through quality and technology, but
              also to fulfill its corporate social responsibility.
            </p>
          </div>
          <img src="/images/ceo.jpg" alt="ceo" className="w-44 md:h-32 h-44" />
        </div>

        {/* end first row */}
        {/* second row */}
        <div className=" flex md:items-start flex-col space-y-1 my-5">
          <p className="text-black font-bold text-lg">A. B. M Whaiduzzaman</p>
          <p>BD ELECTRICAL SERVICES</p>
        </div>

        {/* end of second row */}
      </div>

      <Footer />
    </div>
  );
}
