import React from "react";
import Footer from "../Footer";
import MobileNavbar from "../MobileNavbar";
import Navbar from "../Navbar";

export default function KeyPersonnel() {
  return (
    <div>
      <div>
        <div className="hidden lg:block">
          <Navbar />
        </div>
        <div className="block lg:hidden">
          <MobileNavbar />
        </div>
      </div>

      <div className="my-10 mx-10">
        <div className="flex justify-center items-center my-8">
          <h1 className=" text-black font-bold text-2xl">KEY PERSONNEL</h1>
        </div>
        <div className=" flex justify-center items-center">
          <div className="overflow-x-auto">
            <table className="table w-full">
              {/* <!-- head --> */}
              <thead>
                <tr>
                  <th>Name/Designation</th>
                  <th>Expertise Field</th>
                </tr>
              </thead>
              <tbody>
                {/* <!-- row 1 --> */}
                <tr>
                  <td>
                    <div className=" flex flex-col">
                      <p>Md. Jahangir Hossain</p>
                      <p>Chairman</p>
                      <p>BD Electrical Services</p>
                    </div>
                  </td>
                  <td>
                    <div className=" flex flex-col">
                      <p>Ex. Chief Engineer System Operation &</p>
                      <p>SCADA, DPDC</p>
                      <p>B.Sc. Engg (Machanical), BUET</p>
                    </div>
                  </td>
                </tr>
                {/* <!-- row 2 --> */}
                <tr>
                  <td>
                    <div className=" flex flex-col">
                      <p>A. B. M Whaiduzzaman</p>
                      <p>CEO</p>
                      <p>BD Electrical Services</p>
                    </div>
                  </td>
                  <td>
                    <div className=" flex flex-col">
                      <p>Expert in Industrial Automation & </p>
                      <p>process Instrumentation works.</p>
                    </div>
                  </td>
                </tr>
                {/* <!-- row 3 --> */}
                <tr>
                  <td>
                    <div className=" flex flex-col">
                      <p>Dr. Riffat Sharmin </p>
                      <p>Managing Director</p>
                      <p>BD Electrical Services</p>
                    </div>
                  </td>
                  <td>
                    <div className=" flex flex-col">
                      <p>Experienced in preparing complex </p>
                      <p>project profiles </p>
                    </div>
                  </td>
                </tr>
                {/* <!-- row 4 --> */}
                <tr>
                  <td>
                    <div className=" flex flex-col">
                      <p>MD. Azadul Islam </p>
                      <p>Technical Director</p>
                      <p>BD Electrical Services</p>
                    </div>
                  </td>
                  <td>
                    <div className=" flex flex-col">
                      <p>Experience in Design, Supply, sub station </p>
                      <p>Installation, Commissioning and </p>
                      <p>Maintenance of LV, MV, HV, & EHV </p>
                      <p>Electrical system Up to 400kV </p>
                    </div>
                  </td>
                </tr>
                {/* <!-- row 5 --> */}
                <tr>
                  <td>
                    <div className=" flex flex-col">
                      <p>MD Hasibul Hasan </p>
                      <p>Sales & Marketing Manager</p>
                      <p>BD Electrical Services</p>
                    </div>
                  </td>
                  <td>
                    <div className=" flex flex-col">
                      <p>Expert in project sales, e-gp tender</p>
                      <p>documentation & soursing vendor </p>
                    </div>
                  </td>
                </tr>
                {/* <!-- row 6 --> */}
                <tr>
                  <td>
                    <div className=" flex flex-col">
                      <p>Kajol Khandoker </p>
                      <p>Project Manager</p>
                      <p>BD Electrical Services</p>
                    </div>
                  </td>
                  <td>
                    <div className=" flex flex-col">
                      <p>Experienced especially in 33/11 KV</p>
                      <p>substation works & overhead lines. </p>
                    </div>
                  </td>
                </tr>
                {/* <!-- row 7 --> */}
                <tr>
                  <td>
                    <div className=" flex flex-col">
                      <p>MD. Shimul Mollik </p>
                      <p>Project Co-ordinator</p>
                      <p>BD Electrical Services</p>
                    </div>
                  </td>
                  <td>
                    <div className=" flex flex-col">
                      <p>Skilled in Power System Planning, testing</p>
                      <p>of Electrical Installation, Performing </p>
                      <p>Energy Audit and also automation expert </p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
