import React from "react";
import Footer from "../Footer";
import MobileNavbar from "../MobileNavbar";
import Navbar from "../Navbar";

export default function Residential() {
  const bgBanner1 = "/images/service.jpg";
  return (
    <div>
      <div>
        <div className="hidden lg:block">
          <Navbar />
        </div>
        <div className="block lg:hidden">
          <MobileNavbar />
        </div>
      </div>
      {/* section 1 */}

      <div
        style={{
          backgroundImage: `url(${bgBanner1})`,
          backgroundSize: "cover",
        }}
        className="h-96 w-full flex justify-center items-center "
      >
        <h1 className=" text-black font-bold md:text-4xl text-2xl uppercase">
          residentail services
        </h1>
      </div>

      {/* end section 1 */}

      {/* section 2  */}
      <div className="my-10 mx-16 flex justify-center items-start flex-col md:items-start">
        <p className=" text-gray-800 text-lg mb-4">
          Electrical Service Upgrades (“Heavy-Up”) to Older Homes, Restore power
          to dead outlets, electrical panels and fuse boxes, Installing new
          lighting or fans, Restore power to tripped GFCI outlets, Repair
          overheating circuit breakers or electrical panels, Installing
          “Whole-House” surge protection.
        </p>
        <h1 className=" text-gray-800 text-lg font-bold mb-4">Services</h1>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
          <p className=" text-gray-800 text-lg ">Annual A/C inspection.</p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
          <p className=" text-gray-800 text-lg ">
            Annual electrical inspection.
          </p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
          <p className=" text-gray-800 text-lg ">Design-Build Services.</p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
          <p className=" text-gray-800 text-lg ">
            Supply and install Sensor light.
          </p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
          <p className=" text-gray-800 text-lg ">Lighting Fixtures.</p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
          <p className=" text-gray-800 text-lg ">Replace hot plates.</p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
          <p className=" text-gray-800 text-lg ">Switchboard Upgrade.</p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
          <p className=" text-gray-800 text-lg ">Critical Installations.</p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
          <p className=" text-gray-800 text-lg ">
            LT and HT Meter Supply and Installation.
          </p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
          <p className=" text-gray-800 text-lg ">
            Domestic House Electrical Wiring.
          </p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
          <p className=" text-gray-800 text-lg ">
            Meter Supply, Support, Customization and Installation.
          </p>
        </div>
      </div>

      {/* end section 2 */}
      <Footer />
    </div>
  );
}
