import React from "react";
import Footer from "../Footer";
import MobileNavbar from "../MobileNavbar";
import Navbar from "../Navbar";

export default function CustomerCare() {
  return (
    <div>
      <div>
        <div className="hidden lg:block">
          <Navbar />
        </div>
        <div className="block lg:hidden">
          <MobileNavbar />
        </div>
      </div>
      {/* section 1 */}

      <div className="mx-10 my-10">
        <div className="flex justify-center items-center my-8 flex-col">
          <h1 className=" text-black font-bold text-2xl my-5">CUSTOMER CARE</h1>
          <p className="mb-4">
            The management of BD Electrical Services believes that client
            satisfaction is an important key to the success of business. This
            message is cascaded throughout the company. We have developed a
            comprehensive approach to client care and to minimizing defects on
            all our schemes. Our objective is to achieve client`s satisfaction
            and repeat business by providing Engineering solutions that conform
            to agreed requirements.
          </p>
          <p className="mb-4">
            BD Electrical Services has built its foundations on relationships
            with partners, associations, clients, contractors, and employees.
            Our reputation is based upon recommendations and this has helped set
            us apart from our competitors. The code of conduct at BD Electrical
            Services stems from traditional values within our workforce. Trust,
            honesty and understanding encompass many of our beliefs and for your
            own peace of mind we have implemented the following and will
            endeavor to:
          </p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
          <p className=" text-gray-800 text-lg ">
            Be polite, friendly and courteous.
          </p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
          <p className=" text-gray-800 text-lg ">
            Maintain a high stand of professionalism.
          </p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
          <p className=" text-gray-800 text-lg ">
            Carry cooperative and flexible attitudes.
          </p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
          <p className=" text-gray-800 text-lg ">Acknowledge concerns.</p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
          <p className=" text-gray-800 text-lg ">Be informative.</p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
          <p className=" text-gray-800 text-lg ">Listen to feedback.</p>
        </div>
        <div className="flex justify-center items-center my-8 flex-col">
          <h1 className=" text-black font-bold text-2xl my-5 uppercase">
            Client satisfaction & feedback
          </h1>
        </div>
        <p className="mb-8">
          At BD Electrical Services we are constantly working to improve our
          quality of service to our client and want to hear feedback from our
          stakeholders. In order to maintain our continuing high standards we
          issue a client satisfaction report on all completed projects that our
          client may complete and return. This way we constantly monitor the
          quality of workmanship through to the quality of management.
        </p>
        <p className="mb-8">
          We always encourage our clients to submit their insights, performance
          score and feedback.
        </p>
      </div>

      {/* end section 1 */}

      <Footer />
    </div>
  );
}
