import React from "react";

export default function Banner() {
  const bgBanner1 = "/images/home-bannner.jpg";
  const bgBanner =
    "https://i.ibb.co/r6krmY7/product-research-to-meet-your-needs-1024x576.png";
  return (
    <div
      style={{ backgroundImage: `url(${bgBanner1})`, backgroundSize: "cover" }}
      className=" h-[500px] lg:h-[600px] bg-zinc-900 text-white text-center  flex justify-center items-center"
    >
      <p className=" uppercase font-bold text-4xl">
        Welcom to BD Electrical Services
      </p>
    </div>
  );
}
