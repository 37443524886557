import React from "react";
import Footer from "../Footer";
import MobileNavbar from "../MobileNavbar";
import Navbar from "../Navbar";

export default function AutomationInstrumentWork() {
  const bgBanner1 = "/images/automation.jpg";
  return (
    <div>
      <div>
        <div className="hidden lg:block">
          <Navbar />
        </div>
        <div className="block lg:hidden">
          <MobileNavbar />
        </div>
      </div>

      {/* section 1 */}

      <div
        style={{
          backgroundImage: `url(${bgBanner1})`,
          backgroundSize: "cover",
        }}
        className="h-96 w-full flex justify-center items-center px-14"
      >
        <h1 className=" text-white font-bold md:text-4xl text-2xl uppercase">
          AUTOMATION & INSTRUMENTATION WORK
        </h1>
      </div>

      {/* end section 1 */}

      {/* section 2 */}

      <div className="my-10 mx-16 flex justify-center items-start flex-col md:items-start">
        <p className=" text-gray-800 text-lg mb-4">
          BD Electrical Services is the first organization embarked in the
          horizon of Bangladesh in view to providing complete automation
          solution to serve local industries with local and foreign resources.
          Being a dynamic and continuously growing system integrator of SIEMENS
          Bangladesh Ltd, we can meet our clients’ needs flexibly and
          professionally.
        </p>
        <p className=" text-gray-800 text-lg mb-4">
          We can provide comprehensive solution to problem and our engineering
          team is capable of handling complex and demanding applications from
          system design to install and commission on your plant floor. This
          means first and effective implementation of your new technology at
          lower cost.
        </p>
        <p className=" text-gray-800 text-lg mb-4">
          BD Electrical Services holds the cogent technological know how to
          program, install and tune a diverse variety of PLCs for many different
          Processes.
        </p>

        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
          <p className=" text-gray-800 text-lg ">PLC control Programming.</p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
          <p className=" text-gray-800 text-lg ">
            Operator interface programming.
          </p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
          <p className=" text-gray-800 text-lg ">
            PC-based control Programming with field communication.
          </p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
          <p className=" text-gray-800 text-lg ">HMI/SCADA programming.</p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
          <p className=" text-gray-800 text-lg ">
            Installation, Programming and commissioning of various types DC & AC
            drives.
          </p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
          <p className=" text-gray-800 text-lg ">
            Installation and commissioning of various instrumentation works.
          </p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
          <p className=" text-gray-800 text-lg ">
            Industrial control panel design and assembly.
          </p>
        </div>
        <div>
          <h1 className=" text-black font-bold text-3xl mb-4">
            Nasir Glassware & Tube Industries Ltd.
          </h1>
          <p>
            Supply, Programming, Installation & Commissioning of PLC for Water
            Demineralization Plant.
          </p>
          <h1 className=" text-black font-bold text-3xl my-4">
            DPDC (Dhaka Power Distribution Company)
          </h1>
          <p>Supply, Programming & Commissioning of SCADA System for DPDC.</p>
        </div>
      </div>

      {/* end section 2 */}
      {/* section 3 */}
      <div className=" my-5 flex justify-center items-center">
        <h1 className=" text-black font-bold text-2xl my-5">
          SOME OF VALUABLE PROJECTS
        </h1>
      </div>
      <div className=" grid grid-cols-1 md:grid-cols-4 gap-4 md:mx-12 mx-24 my-5">
        <div>
          <img
            className=" h-72 w-72 shadow-lg border-2 border-gray-400"
            src="/images/ap1.jpg"
            alt="project1"
          />
        </div>
        <div>
          <img
            className=" h-72 w-72 shadow-lg border-2 border-gray-400"
            src="/images/ap2.jpg"
            alt="project1"
          />
        </div>
        <div>
          <img
            className=" h-72 w-72 shadow-lg border-2 border-gray-400"
            src="/images/ap3.jpg"
            alt="project1"
          />
        </div>
        <div>
          <img
            className=" h-72 w-72 shadow-lg border-2 border-gray-400"
            src="/images/ap4.jpg"
            alt="project1"
          />
        </div>
        <div>
          <img
            className=" h-72 w-72 shadow-lg border-2 border-gray-400"
            src="/images/ap5.jpg"
            alt="project1"
          />
        </div>
        <div>
          <img
            className=" h-72 w-72 shadow-lg border-2 border-gray-400"
            src="/images/ap6.jpg"
            alt="project1"
          />
        </div>
        <div>
          <img
            className=" h-72 w-72 shadow-lg border-2 border-gray-400"
            src="/images/ap7.jpg"
            alt="project1"
          />
        </div>
        <div>
          <img
            className=" h-72 w-72 shadow-lg border-2 border-gray-400"
            src="/images/ap8.jpg"
            alt="project1"
          />
        </div>
      </div>

      {/* section 3 end */}
      <Footer />
    </div>
  );
}
