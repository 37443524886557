import React from "react";
import Footer from "../Footer";
import MobileNavbar from "../MobileNavbar";
import Navbar from "../Navbar";

export default function CivilEngineering() {
  const bgBanner1 = "/images/civil.jpg";
  return (
    <div>
      <div>
        <div className="hidden lg:block">
          <Navbar />
        </div>
        <div className="block lg:hidden">
          <MobileNavbar />
        </div>
      </div>

      {/* section 1 */}

      <div
        style={{
          backgroundImage: `url(${bgBanner1})`,
          backgroundSize: "cover",
        }}
        className="h-96 w-full flex justify-center items-center px-14"
      >
        <h1 className=" text-white font-bold md:text-4xl text-2xl uppercase">
          OUR CIVIL WORK
        </h1>
      </div>

      {/* end section 1 */}

      {/* section 2 */}

      <div className="my-10 mx-16 flex justify-center items-start flex-col md:items-start">
        <p className=" text-gray-800 text-lg mb-4">
          BD Electrical Services has over the past few years been constructing
          and Factory with reputation. The company completed turn-key 2 nos
          33/11kV Sub-Station with control building & equipment foundation. And
          Now its working in Hilton Hotel (Sub-contractor) 33/11 KV Switching
          Station with control building & foundation. BD Electrical Services
          Straddles related construction businesses.
        </p>

        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
          <p className=" text-gray-800 text-lg ">
            Construction of Industrial Buildings.
          </p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
          <p className=" text-gray-800 text-lg ">
            Construction of Powerhouse Control Buildings.
          </p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
          <p className=" text-gray-800 text-lg ">
            Construction of Equipment Foundation.
          </p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
          <p className=" text-gray-800 text-lg ">
            Construction of Transportation Infrastructure.
          </p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
          <p className=" text-gray-800 text-lg ">
            Construction of Power Transmission & Distribution.
          </p>
        </div>
      </div>

      {/* end section 2 */}

      {/* section 3 */}
      <div className=" my-5 flex justify-center items-center">
        <h1 className=" text-black font-bold text-2xl my-5">
          SOME OF VALUABLE PROJECTS
        </h1>
      </div>
      <div className=" grid grid-cols-1 md:grid-cols-4 gap-4 md:mx-12 mx-24 my-5">
        <div>
          <img
            className=" h-72 w-72 shadow-lg border-2 border-gray-400"
            src="/images/cp1.jpg"
            alt="project1"
          />
        </div>
        <div>
          <img
            className=" h-72 w-72 shadow-lg border-2 border-gray-400"
            src="/images/cp2.jpg"
            alt="project1"
          />
        </div>
        <div>
          <img
            className=" h-72 w-72 shadow-lg border-2 border-gray-400"
            src="/images/cp3.jpg"
            alt="project1"
          />
        </div>
        <div>
          <img
            className=" h-72 w-72 shadow-lg border-2 border-gray-400"
            src="/images/cp4.jpg"
            alt="project1"
          />
        </div>
      </div>

      {/* section 3 end */}
      <Footer />
    </div>
  );
}
