import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

export default function HomeSlider() {
  return (
    <div className="mx-10">
      <Swiper
        spaceBetween={10}
        slidesPerView={3}
        loop={true}
        pagination={{ clickable: true }}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img
            className="md:h-96 md:w-full h-52 w-full  border-2 border-gray-600"
            src="/images/slider1.jpeg"
            alt=""
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            className="md:h-96 md:w-full h-52 w-full border-2 border-gray-600"
            src="/images/slider2.jpeg"
            alt=""
          />
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <img
            className="md:h-96 md:w-full h-52 w-full border-2 border-gray-600"
            src="/images/slider3.jpeg"
            alt=""
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
