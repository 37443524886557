import React from "react";
import Footer from "../Footer";
import MobileNavbar from "../MobileNavbar";
import Navbar from "../Navbar";

export default function ContactUs() {
  const bgBanner1 = "/images/contact.jpg";
  const bgBanner2 = "/images/bg-about.jpg";
  return (
    <div>
      <div>
        <div className="hidden lg:block">
          <Navbar />
        </div>
        <div className="block lg:hidden">
          <MobileNavbar />
        </div>
      </div>

      {/* section 1 */}

      <div
        style={{
          backgroundImage: `url(${bgBanner1})`,
          backgroundSize: "cover",
        }}
        className="h-96 w-full flex justify-center items-center "
      >
        <h1 className=" text-white font-bold md:text-4xl text-2xl uppercase">
          contact us
        </h1>
      </div>

      {/* end section 1 */}

      {/* section 2 */}

      <div className=" flex flex-col justify-center items-center my-16">
        <p className=" text-gray-400 uppercase text-sm font-medium mb-4">
          WE WOULD LOVE TO WORK WITH YOU
        </p>
        <p className=" text-black text-4xl uppercase font-bold">
          How Can We Help
        </p>
      </div>

      {/* end section 2 */}

      {/* section 3 */}

      <div
        style={{
          backgroundImage: `url(${bgBanner2})`,
          backgroundSize: "cover",
        }}
        className=" md:h-[900px] md:w-full flex justify-center items-center "
      >
        <div className=" bg-white md:m-32 md:w-3/4 my-4">
          <div className=" flex justify-center items-center my-3">
            <p className=" text-black font-bold text-xl uppercase">
              contact US
            </p>
          </div>
          <h3 className=" text-orange-500 uppercase text-lg font-medium pl-8">
            office
          </h3>
          <ul className="pl-8 pr-8 space-y-1 mb-4">
            <li className=" text-gray-700  text-sm">BD Electrical Services</li>
            <li className=" text-gray-700  text-sm">
              House : Hasna Monjil, Holding No: G P TA 39/1
            </li>
            <li className=" text-gray-700  text-sm">
              Moddho badda link road, Dhaka
            </li>
            <li className=" text-gray-700  text-sm">Phone: +88 01791-001818</li>
            <li className=" text-gray-700  text-sm">
              E-mail: info@bdelectricalservices.com
            </li>
            <li className=" text-gray-700  text-sm">
              Web: www.bdelectricalservices.com
            </li>
          </ul>
          <h3 className=" text-orange-500 uppercase text-lg font-medium pl-8">
            BRANCH OFFICE
          </h3>
          <ul className="pl-8 pr-8 space-y-1 mb-4">
            <li className=" text-gray-700  text-sm">BD Electrical Services</li>
            <li className=" text-gray-700  text-sm">
              House : # Kha 116/1, South Badda, Dhaka -1209, Bangladesh
            </li>
            <li className=" text-gray-700  text-sm">
              Phone: +88 01718956239, +88 01847 364 080
            </li>
          </ul>
        </div>
      </div>

      {/* end section 3 */}
      <Footer />
    </div>
  );
}
