import React from "react";
import Footer from "../Footer";
import MobileNavbar from "../MobileNavbar";
import Navbar from "../Navbar";

export default function MechanicalEngineering() {
  const bgBanner1 = "/images/mechanical.jpg";
  return (
    <div>
      <div>
        <div className="hidden lg:block">
          <Navbar />
        </div>
        <div className="block lg:hidden">
          <MobileNavbar />
        </div>
      </div>

      {/* section 1 */}

      <div
        style={{
          backgroundImage: `url(${bgBanner1})`,
          backgroundSize: "cover",
        }}
        className="h-96 w-full flex justify-center items-center px-14"
      >
        <h1 className=" text-white font-bold md:text-4xl text-2xl uppercase">
          MECHANICAL ENGINEERING
        </h1>
      </div>

      {/* end section 1 */}

      {/* section 2 */}

      <div className="my-10 mx-16 flex justify-center items-start flex-col md:items-start">
        <p className=" text-gray-800 text-lg mb-4">
          BD Electrical Services provides comprehensive mechanical engineering
          services for commercial, public, and institutional projects. We are
          dedicated to providing cost effective solutions with emphasis on
          efficiency and flexibility. This dedication, from the early stages of
          project planning until completion has resulted in many satisfied
          clients that have recommended our engineering services to others for
          their projects.
        </p>
        <p className=" text-gray-800 text-lg mb-4">
          We are continually looking for ways to exceed expectations and make
          our services better. As industry codes and standards change, our
          engineering design standards and specifications also evolve; resulting
          in specifications and drawings that meet all applicable codes, promote
          high quality construction, and create a high quality project works for
          the client.
        </p>
        <p className=" text-gray-800 text-lg mb-4">
          BD Electrical Services possesses experienced team to carry out
          different industrial Mechanical jobs. We have local expert to carry
          out mechanical installation work of machines, equipment`s and Steel
          construction works. Our works include:
        </p>
        <p className=" text-gray-800 text-lg mb-4">
          - Installation , Erection and Maintenance of machineries like
          Industrial Heavy Machineries, Boiler, Compressor, Generator,
          Industrial Pump, Motor, Cooling tower, Condenser etc.
        </p>
        <p className=" text-gray-800 text-lg mb-4">
          - Installation and Erection of Industrial Pipe line, Duct Line, Pipe.
        </p>
        <p className=" text-gray-800 text-lg mb-4">
          - Gantry’s and support system with other accessories like foot valve,
          gate valve, butterfly valve etc.
        </p>
      </div>

      {/* end section 2 */}
      {/* section 3 */}
      <div className=" my-5 flex justify-center items-center">
        <h1 className=" text-black font-bold text-2xl my-5">
          SOME OF VALUABLE PROJECTS
        </h1>
      </div>
      <div className=" grid grid-cols-1 md:grid-cols-3 gap-4 mx-24 my-5">
        <div>
          <img
            className=" h-72 w-72 shadow-lg border-2 border-gray-400"
            src="/images/mp1.jpg"
            alt="project1"
          />
        </div>
        <div>
          <img
            className=" h-72 w-72 shadow-lg border-2 border-gray-400"
            src="/images/mp2.jpg"
            alt="project1"
          />
        </div>
        <div>
          <img
            className=" h-72 w-72 shadow-lg border-2 border-gray-400"
            src="/images/mp3.jpg"
            alt="project1"
          />
        </div>
      </div>

      {/* section 3 end */}
      <Footer />
    </div>
  );
}
