
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AboutUs from './about_component/AboutUs';
import './App.css';
import Banner from './components/Home_Component/Banner';
import HomePage from './components/Home_Component/HomePage';
import ContactUs from './contact_component/ContactUs';
import CustomerCare from './customer_care_component/CustomerCare';
import AutomationInstrumentWork from './expertise_component/AutomationInstrumentWork';
import CivilEngineering from './expertise_component/CivilEngineering';
import ElectricalEngineering from './expertise_component/ElectricalEngineering';
import MechanicalEngineering from './expertise_component/MechanicalEngineering';
import Navbar from './Navbar';
import Environment from './policy_component/Environment';
import EqualOpportunityPolicy from './policy_component/EqualOpportunityPolicy';
import HealthSafetyWelfare from './policy_component/HealthSafetyWelfare';
import Products from './product_component/Products';
import Projects from './project_component/Projects';
import Commercial from './service_component/Commercial';
import Industrial from './service_component/Industrial';
import Residential from './service_component/Residential';
import Substation from './service_component/Substation';
import Ceo from './team_component/Ceo';
import KeyPersonnel from './team_component/KeyPersonnel';
import Md from './team_component/Md';
import MessageFromChairman from './team_component/MessageFromChairman';
import Td from './team_component/Td';


function App() {
  return (
    <div>
      <BrowserRouter>
      <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/aboutus" element={<AboutUs />} />
      <Route path="/contactus" element={<ContactUs/>} />
      <Route path="/projects" element={<Projects/>} />
      <Route path="/policy/equal-opportunity" element={<EqualOpportunityPolicy/>} />
      <Route path="/policy/health-safety-welfare" element={<HealthSafetyWelfare/>} />
      <Route path="/policy/environment" element={<Environment/>} />
      <Route path="/services/commercial" element={<Commercial/>} />
      <Route path="/services/industrial" element={<Industrial/>} />
      <Route path="/services/residentail" element={<Residential/>} />
      <Route path="/services/substation" element={<Substation/>} />
      <Route path="/expertise/electrical-engineering" element={<ElectricalEngineering/>} />
      <Route path="/expertise/automation-instrumentation-work" element={<AutomationInstrumentWork/>} />
      <Route path="/expertise/civil-engineering" element={<CivilEngineering/>} />
      <Route path="/expertise/mechanical-engineering" element={<MechanicalEngineering/>} />
      <Route path="/products" element={<Products/>} />
      <Route path="/team/chairman" element={<MessageFromChairman/>} />
      <Route path="/team/ceo" element={<Ceo/>} />
      <Route path="/team/md" element={<Md/>} />
      <Route path="/team/td" element={<Td/>} />
      <Route path="/team/key-personnel" element={<KeyPersonnel/>} />
      <Route path="/customer-care" element={<CustomerCare/>} />

      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
