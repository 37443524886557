import React from "react";
import Footer from "../Footer";
import MobileNavbar from "../MobileNavbar";
import Navbar from "../Navbar";

export default function Substation() {
  const bgBanner1 = "/images/service.jpg";
  return (
    <div>
      <div>
        <div className="hidden lg:block">
          <Navbar />
        </div>
        <div className="block lg:hidden">
          <MobileNavbar />
        </div>
      </div>
      {/* section 1 */}

      <div
        style={{
          backgroundImage: `url(${bgBanner1})`,
          backgroundSize: "cover",
        }}
        className="h-96 w-full flex justify-center items-center "
      >
        <h1 className=" text-black font-bold md:text-4xl text-2xl uppercase">
          substation services
        </h1>
      </div>

      {/* end section 1 */}

      {/* section 2  */}
      <div className="my-10 mx-16 flex justify-center items-start flex-col md:items-start">
        <p className=" text-gray-800 text-lg mb-4">
          A substation is a part of an electrical generation, transmission and
          distribution system. Substations transform voltage from high to low,
          or the reverse, or perform any of several other important functions.
        </p>
        <h1 className=" text-gray-800 text-lg font-bold mb-4">Services</h1>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
          <p className=" text-gray-800 text-lg ">
            Power & Distribution Transformer.
          </p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
          <p className=" text-gray-800 text-lg ">Cast Resin Transformer.</p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
          <p className=" text-gray-800 text-lg ">33 & 11 KV HT Switchgear.</p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
          <p className=" text-gray-800 text-lg ">LT Switchgear.</p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
          <p className=" text-gray-800 text-lg ">Capacitor/ PFI plant.</p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
          <p className=" text-gray-800 text-lg ">Motor Control Centre.</p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
          <p className=" text-gray-800 text-lg ">
            Automation / Logic Control System.
          </p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
          <p className=" text-gray-800 text-lg ">Automation Starter.</p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
          <p className=" text-gray-800 text-lg ">
            Auto Water (Tank) Level Control System.
          </p>
        </div>
        <div className=" flex flex-row space-x-4 mb-4">
          <div className="md:h-4  w-4 rounded-full bg-gray-700 mt-2"></div>
          <p className=" text-gray-800 text-lg ">
            Distribution board / SDB / LDB.
          </p>
        </div>
      </div>

      {/* end section 2 */}

      {/* section 3 */}
      <div className="my-10 mx-10">
        <h1 className="text-black text-2xl font-bold my-5">TRANSFORMER</h1>
        <p className="mb-5">
          BDES Manufactures, Assembles and Engaged in Marketing of electrical
          substation equipment, Power Transformer range from 5MVA to 10 MVA.
        </p>
        <div className="flex justify-center items-center">
          <img src="/images/transformer1.jpg" alt="transformer" />
        </div>
        {/* end transformer */}
        <h1 className="text-black text-2xl font-bold my-5">HT PANEL</h1>
        <div className=" flex md:flex-row md:justify-between flex-col mb-5">
          <div className="md:w-[500px]">
            BDES makes own Factory assembled 14 SWG Sheet steel clad, dust and
            vermin proof free standing floor mounting, with heat proof/powder
            quoted painted 11 KV & 33KV, 50Hz, 3 phase, indoor or outdoor type
            high-tension switchgears having hard drawn electrolytic copper
            busbar and other protection relays & metering equipment.
          </div>
          <div>
            <img
              className="w-72 h-44 mb-5"
              src="/images/htpanel.jpg"
              alt="htpanel"
            />
          </div>
        </div>
        {/* end ht panel */}
        <h1 className="text-black text-2xl font-bold my-5">LT PANEL</h1>
        <div className=" flex md:flex-row md:justify-between flex-col mb-5">
          <div className="md:w-[500px]">
            BDES makes sheet steel clad, dust and vermin proof, free standing
            floor mounting type Low-Tension Switch-gears for 3-pharse, 4-wire,
            415/240V system with TPN hard drawn electrolytic copper busbars &
            equipped with suitable circuit breakers, protective relays,
            measuring equipment.
          </div>
          <div>
            <img
              className="w-72 h-44 mb-5"
              src="/images/ltpanel.jpg"
              alt="ltpanel"
            />
          </div>
        </div>
        {/* end lt panel */}
      </div>
      {/* end section 3 */}

      <Footer />
    </div>
  );
}
